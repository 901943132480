<template>
<el-dialog v-model="visible" title="云仓入库" custom-class="pifpis-dialog" :close-on-click-modal="false" :before-close="close">
  <div class="put-in-for-pi-storage">
    <div class="pifpis-title">基本信息</div>
    <el-form :model="form" v-loading="loading" class="pifpis-base-info" label-width="130px" :rules="rules" ref="ruleFormRef">
      <el-row>
        <el-form-item label="运单号：" prop="expressCode">
          <el-input placeholder="请输入运单号" v-model="form.expressCode" />
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="选择供应商：" prop="supplierCode">
          <el-select v-model="form.supplierCode" @change="supplierChange" placeholder="请选择" size="small">
            <el-option v-for="(item, index) in options.supplierInfo" :key="index" :label="item.supplierName" :value="item.clpsSupplierNo" />
          </el-select>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="联系人：" prop="name">
          <el-input placeholder="请输入联系人" v-model="form.name" />
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="联系电话：" prop="mobile">
          <el-input placeholder="请输入联系电话" v-model="form.mobile" />
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="地址：" prop="detailAddress">
          <el-input placeholder="请输入地址" v-model="form.detailAddress" />
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="选择入库库房：" prop="warehouseCode">
          <el-select v-model="form.warehouseCode" placeholder="请选择" size="small">
            <el-option v-for="(item, index) in options.wareHousInfo" :key="index" :label="item.warehouseName" :value="item.warehouseCode" />
          </el-select>
        </el-form-item>
      </el-row>
    </el-form>
    <div class="pifpis-title">商品信息</div>
    <el-form :model="proForm" v-loading="loading" class="pifpis-base-info" label-width="130px" :rules="proRules" ref="ruleProFormRef">
      <el-row>
        <el-form-item label="选择商品：" prop="itemNo">
          <el-select v-model="proForm.itemNo" placeholder="请选择" size="small">
            <el-option v-for="(item, index) in options.itemOption" :key="index" :label="item.txt_commodity_item_name" :value="item.txt_commodity_item_code" />
          </el-select>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="商品数量：" prop="planQty">
          <el-input placeholder="请输入商品数量" v-model="proForm.planQty" />
        </el-form-item>
      </el-row>
    </el-form>
  </div>
  <template #footer>
    <div class="dialog-footer">
      <el-button @click="close(true)" type="primary">确认订购</el-button>
    </div>
  </template>
</el-dialog>
</template>

<script>
// 创建出库单 - 耗材订购
import { useStore } from 'vuex';
import { onMounted, reactive, toRefs, ref } from 'vue';
import { ElMessage, FormInstance } from 'element-plus';
import { getSupplierInfoAndwareHousInfo, getGoodsInfo, createPIOrder } from "api/apis.js";
export default {
  setup(prop, ctx) {
    const store = useStore();
    let moment = require("moment");
    const state = reactive({
      ruleFormRef: ref(FormInstance),
      ruleProFormRef: ref(FormInstance),
      visible: true,
      proForm: {
        itemNo: "",
        planQty: ""
      },
      form: {
        expressCode: "",
        supplierCode: "",
        warehouseCode: "",
        name: "",
        mobile: "",
        detailAddress: "",
        supplierName: ""
      },
      options: {
        supplierInfo: [],
        wareHousInfo: [],
        itemOption: {}
      },
      loading: false,
      proRules: {
        itemNo: [{ required: true, message: "请选择商品", trigger: "blur" }],
        planQty: [
          { required: true, message: "请输入商品数量", trigger: "blur" },
          { required: true, message: "只能输入正整数", pattern: /^[0-9]*[1-9][0-9]*$/, trigger: "blur" },
        ]
      },
      rules: {
        supplierCode: [{ required: true, message: "请选择供应商姓名", trigger: "blur" }],
        warehouseCode: [{ required: true, message: "请选择入库库房", trigger: "blur" }],
        name: [{ required: true, message: "请输入联系人姓名", trigger: "blur" }],
        mobile: [
          { required: true, message: "请输入联系方式", trigger: "blur" },
          // { required: true, message: "手机号格式不正确", pattern: /^((0\d{2,3}-\d{7,8})|(1[34578]\d{9}))$/, trigger: "blur" },
          { required: true, message: "手机号格式不正确", pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, trigger: "blur" },
        ],
        detailAddress: [{ required: true, message: "请输入地址", trigger: "blur" }]
      },
    });

    onMounted(() => {
      getData();
      getGoods();
    });

    // 获取耗材列表
    const getData = () => {
      state.loading = true;
      getSupplierInfoAndwareHousInfo().then(response => {
        if (response.code == 200) {
          state.options.supplierInfo = response.data.supplierInfo;
          state.options.wareHousInfo = response.data.wareHousInfo;
        } else {
          ElMessage.error(response.msg);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.loading = false;
      })
    };

    const getGoods = () => {
      getGoodsInfo().then(response => {
        if (response.code == 200) {
          state.options.itemOption = response.data;
        } else {
          ElMessage.error(response.msg);
        }
      }).catch(e => {
        console.error(e);
      })
    };

    // 供应商选择器选择事件
    const supplierChange = () => {
      state.loading = true;
      let tempIndex = state.options.supplierInfo.findIndex(t => t.clpsSupplierNo == state.form.supplierCode);
      state.form.name = state.options.supplierInfo[tempIndex].contacts;
      state.form.mobile = state.options.supplierInfo[tempIndex].mobile;
      state.form.detailAddress = state.options.supplierInfo[tempIndex].address;
      state.form.supplierName = state.options.supplierInfo[tempIndex].supplierName;
      state.loading = false;
    };

    // dialog 关闭事件
    const close = (type = false) => {
      // 确认入库
      if (type == true) {
        // 启动必填校验
        state.ruleFormRef.validate(valid => {
          if (valid) {
            state.ruleProFormRef.validate(valid_2 => {
              if (valid_2) {
                state.loading = true;
                if (state.form.expressCode == "") delete state.form.expressCode;
                let param = { ...state.form, ...state.proForm };
                param.txt_creator = store.getters.userInfo.name || "系统用户",
                  param.dt_create = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
                createPIOrder(param).then(response => {
                  if (response.code == 200) {
                    ElMessage.success("入库成功");
                    ctx.emit("close", true);
                  } else {
                    ElMessage.error(response.msg);
                  }
                }).catch(e => {
                  console.error(e);
                }).finally(() => {
                  state.loading = false;
                })
              }
            })
          }
        })
      } else {
        // 仅关闭dialog
        ctx.emit("close", false);
      }
    }

    return {
      ...toRefs(state),
      close,
      getData,
      supplierChange,
      getGoods
    }
  }
}
</script>

<style lang="scss">
.pifpis-dialog {
  width: 600px;
  height: 550px;

  .put-in-for-pi-storage {
    .pifpis-title {
      font-weight: bold;
      font-size: 16px;
      margin: 10px 0;

      &:first-child {
        margin-top: 0;
      }
    }

    .pifpis-base-info {
      margin: 8px 0 0 16px;
      width: calc(100% - 16px);

      .el-form-item {
        margin-right: 0;
        width: 100%;

        .el-select {
          width: 100%;
        }
      }
    }

  }
}
</style><style lang="scss">
.pifpis-message-box-num {
  color: #409eff;
  font-weight: bold;
}
</style>
