<template>
<div class="put-in-storage-index" id="page">
  <el-row class="pisi-form-line row-start-between">
    <el-form :model="form" class="row-center-start" ref="formRef">
      <el-row>
        <el-form-item prop="keyword">
          <el-input v-model="form.keyword" placeholder="商品名称/供应商名称">
            <template #prepend>
              <i class="iconfont icon-sousuo" />
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="dateRange">
          <el-date-picker value-format="YYYY-MM-DD" v-model="form.dateRange" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" />
        </el-form-item>
        <el-form-item label="采购单状态" prop="txt_poOrder_status">
          <el-select v-model="form.txt_poOrder_status" placeholder="请选择" size="small">
            <el-option v-for="(item, index) in options.txtPoOrderStatusOption" :key="index" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="入库状态" prop="txt_warehousing_status">
          <el-select v-model="form.txt_warehousing_status" placeholder="请选择" size="small">
            <el-option v-for="(item, index) in options.txtWarehousingStatusOption" :key="index" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getList()">搜索</el-button>
          <el-button type="info" @click="reset()">重置</el-button>
        </el-form-item>
      </el-row>
    </el-form>
    <el-row>
      <el-button type="primary" @click="dialog.dialogShow=true">云仓入库</el-button>
    </el-row>
  </el-row>
  <el-table :data="table.tableData" v-loading="table.tabelLoading">
    <el-table-column prop="JDentryOrderCode" label="采购单号" align="center">
      <template #default="scope">{{scope.row.JDentryOrderCode||"- -"}}</template>
    </el-table-column>
    <el-table-column prop="supplierName" label="供应商名称" align="center" />
    <el-table-column prop="warehouseName" label="入库库房" :show-overflow-tooltip="true" align="center">
      <template #default="scope">{{scope.row.warehouseName||"- -"}}</template>
    </el-table-column>
    <el-table-column prop="itemName" label="商品名称" align="center" />
    <el-table-column prop="planQty" label="计划入库（件）" align="center" />
    <el-table-column prop="realInstoreQty" label="实际入库（件）" align="center">
      <template #default="scope">{{scope.row.realInstoreQty||0}}</template>
    </el-table-column>
    <el-table-column prop="txt_poOrder_status" label="采购单状态" align="center">
      <template #default="scope">
        <template v-if="scope.row.txt_poOrder_status == 1">初始</template>
        <template v-else-if="scope.row.txt_poOrder_status == 2">取消中</template>
        <template v-else-if="scope.row.txt_poOrder_status == 3">取消完成</template>
        <template v-else-if="scope.row.txt_poOrder_status == 4">完成</template>
        <template v-else>- -</template>
      </template>
    </el-table-column>
    <el-table-column prop="txt_warehousing_status" label="入库状态" align="center">
      <template #default="scope">
        <template v-if="scope.row.txt_warehousing_status == 1">正常确认</template>
        <template v-else-if="scope.row.txt_warehousing_status == 2">异常确认</template>
        <template v-else>- -</template>
      </template>
    </el-table-column>
    <el-table-column prop="createTime" label="建单时间" align="center">
      <template #default="scope">{{scope.row.createTime||"- -"}}</template>
    </el-table-column>
    <el-table-column label="操作" align="center" class-name="table-option">
      <template #default="scope">
        <div class="table-icon" :class="scope.row.txt_poOrder_status==1?'':'disabled'" @click="handleCancel(scope.row)">
          <el-icon>
            <Close />
          </el-icon> 取消入库
        </div>
      </template>
    </el-table-column>
  </el-table>
  <pagination :total="table.total" @sizeChange="(e) => {form.limit=e;getList()}" @pageChange="(e) => {form.page=e;getList()}" />
  <put-in v-if="dialog.dialogShow" @close="(e)=>{dialog.dialogShow=false; if(e){getList()}}" />
</div>
</template>

<script>
// 库存管理 - 创建入库单
import { useStore } from 'vuex';
import { Pagination } from "components";
import { onMounted, reactive, toRefs } from 'vue';
import { getPutInOrderList, cancelOrder } from "api/apis.js";
import { Close } from "@element-plus/icons-vue";
import { ElMessage, ElMessageBox } from "element-plus";
import PutIn from './comps/putIn.vue';
export default {
  components: {
    Pagination,
    PutIn,
    Close
  },
  setup() {
    const store = useStore();
    let moment = require("moment");
    const state = reactive({
      options: {
        txtPoOrderStatusOption: [
          { id: 0, label: "初始", value: 1 },
          { id: 1, label: "取消中", value: 2 },
          { id: 2, label: "取消完成", value: 3 },
          { id: 2, label: "完成", value: 4 }
        ],
        txtWarehousingStatusOption: [
          { id: 0, label: "正常确认", value: 1 },
          { id: 1, label: "异常确认", value: 2 }
        ]
      },
      table: {
        tableData: [],
        tabelLoading: false,
        total: 0,
      },
      form: {
        keyword: "",
        dateRange: [],
        txt_poOrder_status: "",
        txt_warehousing_status: "",
        limit: 10,
        page: 1
      },
      dialog: {
        dialogShow: false,
        dialogData: {}
      }
    });

    onMounted(() => {
      getList();
    })

    // 获取列表数据
    const getList = () => {
      state.table.tabelLoading = true;
      let param = {
        searchKey: state.form.keyword,
        dt_from: (state.form.dateRange || []).length > 0 ? state.form.dateRange[0] : "",
        dt_to: (state.form.dateRange || []).length > 0 ? state.form.dateRange[1] : "",
        txt_poOrder_status: state.form.txt_poOrder_status,
        txt_warehousing_status: state.form.txt_warehousing_status,
        page: state.form.page,
        limit: state.form.limit
      }
      for (let i = 0; i < Object.keys(param).length; i++) {
        let key = Object.keys(param)[i];
        if (param[key] == "") {
          i -= 1;
          delete param[key];
        }
      }
      getPutInOrderList(param).then(response => {
        if (response.code == 200) {
          if (response.data) {
            state.table.tableData = response.data.orderList
            state.table.total = response.data.count;
          } else {
            state.table.tableData = [];
            state.table.total = 0;
          }
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.table.tabelLoading = false;
      })
    };

    // 重置表单
    const reset = () => {
      state.form = {
        keyword: "",
        dateRange: [],
        txt_poOrder_status: "",
        txt_warehousing_status: "",
        limit: state.form.limit,
        page: state.form.page,
      }
      getList();
    };

    // 取消入库
    const handleCancel = (item) => {
      ElMessageBox.confirm(
        `确定要取消编号【${item.JDentryOrderCode}】入库单吗？`,
        '提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }
      ).then(() => {
        state.table.tabelLoading = true;
        cancelOrder({
          t_order_id: item.t_poOrder_id,
          orderType: "CGRK",
          txt_updater: store.getters.userInfo.name || "系统用户",
          dt_update: moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
        }).then(response => {
          if (response.code == 200) {
            ElMessage.success("取消成功");
            getList();
          } else {
            ElMessage.error(response.msg);
          }
        }).catch(e => {
          console.error(e);
        }).finally(() => {
          state.table.tabelLoading = false;
        })
      })
    };

    return {
      ...toRefs(state),
      getList,
      reset,
      handleCancel
    }
  }
}
</script>

<style lang="scss" scoped>
.put-in-storage-index {
  height: 100%;

  .el-table {
    height: calc(100% - 95px);
  }
}
</style>
